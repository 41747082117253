<template>
    <div>
        <div class="video_group">
            <video id="video" width="100%" hidden autoplay playsinline></video>
            <video id="brand_video" width="100%" height="1" hidden autoplay muted playsinline></video>
            <video id="car_purpose_video" width="100%" height="1" hidden autoplay muted playsinline></video>
            <video id="free_video" width="100%" height="1" hidden  muted playsinline></video>
        </div>
        <SpaceConnection
            :spaceId="this.$store.state.spaceId"
            :spaceNm="this.$store.state.spaceNm"
            v-if="this.$store.state.isSpaceConnection"
            @closeModal="linkMySpace()"
            @closeModal2="closeModal()"
        />
        <channel-alert v-if="channelAlret" :alertText="channelAlertText" @closeModal="closeChannelAlert()" />
        <router-view ref="routerview" />

        <Floating
            v-if="floatingShow"
            :channelInfo="channelInfo"
            :currentMusic="this.$store.state.channelMusicInfo"
            :isPlay="this.$store.state.isPlay"
            :recomList="$store.getters['player/getRecomList']"
            @getmychannelinfo="onGetMyChannelInfo"
        />
        <notice-modal />
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import SpaceConnection from '@/components/modal/Space/SpaceConnection2.vue';
import router from '@/router';
import { createMyChannel } from '@/assets/js/webcast';
import Floating from '@/components/Floating.vue';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import Hls from 'hls.js';
import NoticeModalComponent from '@/components/notice/NoticeModalComponent.vue';
export default defineComponent({
  name: 'Layout4',
  components: {
    SpaceConnection,
    Floating,
    'notice-modal': NoticeModalComponent,
    'channel-alert': () => import(`@/components/modal/Space/AlertModal.vue`)
  },
  data () {
    return {
      channelInfo: null,
      recomList: [],
      isSpaceConnection: null,
      floatingShow: false,
      channelAlret: false,
      channelAlertText: '',
      notShowFloatingList: [
        'Main',
        'CesCurationInteriorView',
        'CesPosView',
        'SpaceChoice',
        'SpaceInsert',
        'SpaceInsertStoreMood',
        'SpaceInsertStoreTarget',
        'SpaceInsertStoreUnit',
        'SpaceInsertStoreCountry',
        'SpaceInsertStorePeriod',
        'SpaceInsertStoreFavorite',
        'SpaceInsertEmotion',
        'SpaceInsertFavorite',
        'SpaceInsertGenre',
        'SpaceInsertMood',
        'SpaceInsertPeriod',
        'SpaceInsertStore',
        'SpaceInsertTarget',
        'SpaceInsertUnit',
        'SpacePayment',
        'SpaceInsertCar',
        'SpaceInsertCarMood',
        'SpaceInsertBuilding',
        'UpdateBuilding',
        'SpaceInsertFindChannel',
        'SpaceInsertSearchChannel',
        'SpaceInsertStore5',
        'SpacePaymentFinished',
        'UpdateSpace',
        'PlayerWS',
        'MySpaceUpdateStore5',
        'UpdateCuration',
        'InstallablePlayerUpdateCuration',
        'MySpaceBuilding',
        'CesCurationMoodView',
        'CesCurationCompleteView',
        'CesPlayer',
        'SeongdongLibaryView',
        'SpaceInsertStoreColor',
        'SpaceInsertStoreMaterial',
        'PlayerView',
        'CalendarView',
        'SettingTimeView'
      ]
    };
  },
  created () {
    this.$store.dispatch('curation/settingCurationColors');
    this.$store.dispatch('curation/settingCurationMaterial');
    this.$store.commit('setEmail', this.$cookies.get('email'));
    this.isSpaceConnection = this.$store.watch(
      () => this.$store.getters.gettersIsSpaceConnection,
      (val, oldVal) => {
        if (oldVal === false && val === true) {
          if (this.$route.name === 'PlayerWS') {
            if (!localStorage.getItem('wsTutorialStatus')) {
              this.$store.commit('player/setTutorial', {
                step1: true,
                step2: false
              });
            }
          }
        }
      }
    );
  },
  async mounted () {
    const { name } = this.$route;
    this.$VideoPlayer.setVideoEle();
    this.$BrandPlayer.setBrandVideo();
    this.controlShowFloating(name);
    const spaceId = VueCookies.get('spaceId');
    if (this.$store.state.division !== 'Building') {
      this.initVideoSetting();
    }

    if (spaceId && this.$store.state.division !== 'Building') {
      if (
        this.$store.state.channelId === '' &&
        !this.$store.state.installablePlayer &&
        this.$route.name !== 'PlayerView' &&
        this.$route.name !== 'SeongdongLibaryView'
      ) {
        await this.$store.dispatch('player/createChannel', { retry: true });
      }
      if (this.$store.state.installablePlayer) {
        this.$store.commit('setPlay', false);
      }

      try {
        await this.getChannelInfo();
      } catch (error) {
        await this.retryChannelInfo(0);
      }

      await this.selectRecommendedChannels();
    }

    this.destoryVideo(name);
  },
  methods: {
    async createChannel () {
      const result = await createMyChannel();
      if (result.status === 400) {
        this.$VideoPlayer.onPause();
        this.$store.commit('setPlay', false);
        return false;
      }

      return true;
    },
    closeChannelAlert () {
      if (this.$route.name === 'MySpace') {
        this.channelAlret = false;
      } else {
        this.$router.push({ name: 'MySpace' }).catch(() => {});
        this.channelAlret = false;
      }
    },
    async retryChannelUrl (n) {
      return new Promise((resolve, reject) => {
        if (n < 30) {
          setTimeout(async () => {
            await fetch(this.$store.state.channelUrl)
              .then(() => {
                if (this.$CarPlayer.getPaused()) {
                  this.$VideoPlayer.playerMusic(this.$store.state.channelUrl);
                  this.$VideoPlayer.startLoad(-1);
                  this.$VideoPlayer.onPlay();
                }

                resolve('success');
              })
              .catch(_ => {
                this.retryChannelUrl(n + 1);
              })
              .finally(() => {
                if (this.$store.getters.getCarCurationModal) {
                  this.$VideoPlayer.onPause();
                }
              });
          }, 1500);
        } else {
          reject(new Error('fail'));
        }
      });
    },
    async retryChannelInfo (n) {
      console.log('Layout4 retryChannelInfo');
      return new Promise((resolve, reject) => {
        if (n < 5) {
          const channelId = this.$store.state.channelId;
          const aToken = VueCookies.get('aToken');
          const headers = { 'X-AUTH-TOKEN': aToken };
          setTimeout(() => {
            axios
              .post('/api/player/getChannelInfo', { channelId }, { headers })
              .then(res => {
                const { resultCd, result } = res.data;
                if (resultCd === '0000') {
                  this.channelInfo = result;
                  this.$store.commit('player/setChannelInfo', result);
                  resolve('success');
                } else {
                  this.retryChannelInfo(n + 1);
                }
              })
              .catch(e => {
                this.retryChannelInfo(n + 1);
              });
          }, 2000);
        } else {
          reject(new Error('fail'));
        }
      });
    },
    initVideoSetting () {
      this.$VideoPlayer.registerVideoEvent('play', this.videoPlay);
      this.$VideoPlayer.registerVideoEvent('canplaythrough', this.videoCanplaythrough);
      this.$VideoPlayer.setVolume(this.$store.state.volume);
      this.$VideoPlayer.registerVideoEvent('pause', this.videoPause);
      this.$VideoPlayer.registerVideoEvent('error', this.videoErrorEvent);
    },
    /**
     * @description 비디오 에러이벤트
     * @author CHOI DAE GEON
     */
    async videoErrorEvent () {
      if (this.$store.state.isPlay === '' || this.$store.state.isPlay) {
        await this.retryChannelUrl(1);
      }
    },
    /**
     * ! 어떤 기능인지 추후 확인 후 설명작성 필요
     * @param {*} idx
     * @author CHOI DAE GEON
     */
    checkFn (idx) {
      console.log('paused trigger', 'before: ', idx, ', now: ', this.$store.state.currentMusicIdx);
      if (idx === this.$store.state.currentMusicIdx) {
        this.$VideoPlayer.videoDisPatchEvent('ended');
      }
    },
    /**
     * @description 비디오 Play 이벤트
     * @author CHOI DAE GEON
     */
    async videoPlay () {
      this.$store.commit('setPlay', true);
      console.log('music play start!');
      this.$VideoPlayer.onMute(false);
      navigator.mediaSession.playbackState = 'playing';
      if (this.$store.state.isPlay && !this.$store.state.isBrandMusic && this.$store.state.division !== 'Building') {
        this.checkDupleUse();
        return false;
      }
    },
    /**
     * @description 비디오 중지 이벤트
     * @author CHOI DAE GEON
     */
    async videoPause () {
      this.$store.commit('setPlay', false);
      console.log('video Pause');
      // if (!Hls.isSupported()) {
      // document.querySelector('#video').src = '';
      // }

      navigator.mediaSession.playbackState = 'paused';
      // check media ended
      // source buffers 내에 미디어 소스는 종료로 확인 상태에서 pause 상태로 멈춰 다음 곡이 재생 되지 않는 문제 해결
      try {
        if (this.$store.state.isPlay && this.$store.state.hls !== undefined) {
          // 이용자의 pause 상태 변경 없이 pause 호출 시
          console.log(
            'not triggered',
            this.$store.state.isPlay,
            this.$store.state.hls.bufferController.sourceBuffer.audio.ended
          );
          this.$store.commit('setPlay', false);
          if (this.$store.state.hls.bufferController.sourceBuffer.audio.ended) {
            // 미디어 소스 버퍼의 로딩이 끝까지 완료 된 상태라면.. 1초뒤에도 pause 상태인지 확인 후 처리
            const mIdx = this.$store.state.currentMusicIdx;

            setTimeout(() => {
              this.checkFn(mIdx);
            }, 1000);
          } else if (
            this.$store.state.hls.streamController.fragCurrent.sn < this.$store.state.hls.bufferController.details.endSN
          ) {
            // Stream Controller에서 다음 Fragment를 로딩하지 않고 멈춰 있는 경우가 있다.
            // 현재 Fragment 일련번호와 전체 일련번호가 같지 않다면 복구를 시도한다.
            console.log('Try to load next chunk file and replay.');
            this.$store.state.hls.startLoad();
          }
        }
      } catch (error) {
        console.log('Error', error);
      }
    },
    /**
     * @description 중복체크
     * @author CHOI DAE GEON
     */
    checkDupleUse () {
      const userId = VueCookies.get('userId');
      const eamil = this.$cookies.get('email');
      const spaceId = VueCookies.get('spaceId');
      const sessionId = localStorage.getItem('sessionId');
      const recentSpaceId = localStorage.getItem('recentSpaceId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      if (!this.$store.getters['cesuserinfo/isCesUser'](eamil) && spaceId) {
        axios
          .post('/api/spaceSession/checkDupleUse', { userId, spaceId, sessionId, recentSpaceId }, { headers })
          .then(res => {
            const { resultCd, result } = res.data;
            if (resultCd === '0000') {
              const { resultCd: spaceResultCd, sessionId } = result;
              localStorage.setItem('sessionId', sessionId);
              if (spaceResultCd === 'D0002') {
                this.$VideoPlayer.onPause();
                this.$store.commit('setSpaceConnection', true);
                this.$store.commit('setPlay', false);
              } else if (spaceResultCd === 'D0003') {
                localStorage.setItem('recentSpaceId', spaceId);
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    /**
     * @description closeModal
     * @author CHOI DAE GEON
     */
    closeModal () {
      this.$store.commit('setSpaceConnection', false);

      this.$VideoPlayer.playerMusic(this.$store.state.channelUrl);
      if (!this.$store.getters.getCarCurationModal) {
        this.$VideoPlayer.startLoad(-1);
        this.$VideoPlayer
          .onPlay()
          .then(() => {
            console.log('success');
            this.$store.commit('setPlay', true);

            if (Object.keys(this.$refs.routerview).includes('onSetMusicInfoInterval')) {
              this.$refs.routerview.onSetMusicInfoInterval();
            }
          })
          .catch(err => {
            this.$store.commit('setPlay', false);
            console.log('err : ', err);
          })
          .finally(() => {
            this.$store.commit('player/setIsLoading', false);
          });
      }
    },
    /**
     * @description linkMySpace
     * @author CHOI DAE GEON
     */
    linkMySpace () {
      this.$store.commit('setSpaceConnection', false);
      clearInterval(this.$store.state.musicInfoInterval);
      router.push({ name: 'MySpace' });
    },
    /**
     * @description 내 스페이스 정보
     * @author CHOI DAE GEON
     */
    async getPlayerInfo () {
      const channelId = this.$store.state.channelId;
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      await axios.post('/api/player/getChannelInfo', { channelId }, { headers }).then(res => {
        const result = res.data.result;
        this.$store.commit('player/setChannelInfo', result);
      });
    },
    /**
     * @description 추천채널 조회
     * @author CHOI DAE GEON
     */
    async selectRecommendedChannels () {
      const spaceId = VueCookies.get('spaceId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      await axios
        .post('/api/player/selectSimilarChannels', { spaceId }, { headers })
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            // this.recomList = result;
            const findMyChannel = result.find(item => item.spaceId === spaceId);

            if (findMyChannel) {
              this.$store.commit('player/setMyChannelInfo', findMyChannel);
            }
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    /**
     * @description 채널정보조회
     * @author CHOI DAE GEON
     */
    getChannelInfo () {
      const channelId = this.$store.state.channelId;
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      if (channelId) {
        const spaceId = this.$cookies.get('spaceId') ? this.$cookies.get('spaceId') : null;
        axios
          .post('/api/player/getChannelInfo', { channelId, spaceId }, { headers })
          .then(res => {
            const { resultCd, result } = res.data;
            if (resultCd === '0000') {
              this.channelInfo = result;
              this.$store.commit('player/setChannelInfo', result);
            }
          })
          .catch(e => {
            console.log(e);
          });
      }
    },
    /**
     * @description Floating 채널변경 이벤트
     * @author CHOI DAE GEON
     */
    async onGetMyChannelInfo () {
      await this.getChannelInfo();
      this.$VideoPlayer.playerMusic(this.$store.state.channelUrl);
    },
    /**
     * @description Hls Error handler
     * @param {*} _ event
     * @param {*} data error data
     * @author CHOI DAE GEON
     */
    errorHandler (_, data) {
      try {
        if (document.location.hostname === 'www.aplayz.co.kr') {
          const _store = this.$store;
          (window.gtag || function () {})('event', 'hls_error', {
            space_id: _store.state.spaceId,
            space_nm: _store.state.spaceNm,
            is_fatal: data.fatal,
            error_type: data.type,
            error_detail: data.details
          });
        }
        if (data.fatal && this.$route.name !== 'PlayerWS') {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.error('NETWORK_ERROR : ', data.details);
              if (data.details === 'manifestLoadError') {
                if (!this.$store.getters.getCarCurationModal) {
                  this.$VideoPlayer.playerMusic(this.$store.state.channelUrl);
                  this.$VideoPlayer.startLoad(-1);
                  this.$VideoPlayer.onPlay();
                }
              }
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.error('MEDIA_ERROR', data.details);
              break;
            default:
              this.$VideoPlayer.videoDisPatchEvent('ended');
              break;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    /**
     * @description Floating visible 처리
     * @param {*} name route명
     */
    controlShowFloating (name) {
      const email = this.$cookies.get('email');
      if (this.$store.getters['cesuserinfo/isCesUser'](email)) {
        this.floatingShow = false;
      } else if (!this.notShowFloatingList.includes(name)) {
        this.floatingShow = true;
      } else {
        this.floatingShow = false;
      }
    },
    playAlert () {
      const { name } = this.$route;
      if (name === 'PlayerWS') {
        alert('재생버튼을 클릭해주세요.');
      }
    },
    /**
     * @description Video canplaythrough
     * @author CHOI DAE GEON
     */
    async videoCanplaythrough () {
      console.log('canplaythrough event trigger');
      if (this.$store.getters['player/getIsLoading']) {
        try {
          await this.selectRecommendedChannels();
          await this.getPlayerInfo();
        } catch (error) {
          console.log('error videoCanplaythrough : ', error);
        }
      }

      if (this.$store.state.isPlay === '') {
        const { name } = this.$route;
        if (name !== 'SpaceChoice') {
          const promise = this.$VideoPlayer.onPlay();
          if (this.$store.state.isBrandMusic) {
            this.$VideoPlayer.onMute(true);
          } else {
            this.$VideoPlayer.onMute(false);
          }
          if (promise !== undefined) {
            promise
              .then(_ => {
                if (Hls.isSupported()) {
                  if (!this.$store.state.isBrandMusic && !this.$store.state.isPlay) {
                    this.$VideoPlayer.onMute(false);
                    this.$VideoPlayer.setVolume(this.$store.state.volume);
                  } else if (!this.$VideoPlayer.getMute() && this.$VideoPlayer.getPaused()) {
                    this.$store.commit('setPlay', false);
                    this.playAlert();
                  }
                } else {
                  if (this.$VideoPlayer.getPaused()) {
                    this.playAlert();
                  }
                }
              })
              .catch(err => {
                const readState = this.$VideoPlayer.getReadyState();
                console.error('Ready Status: ', readState, 'Auto-play was prevented: ', err, err.message, typeof err);
                window.videoErr = err;
                if (readState === 4) {
                  // 자동재생 방지로 인한 메시지 또는 수동 재생버튼 클릭 메시지 필요
                  this.$store.commit('setPlay', false);
                  this.playAlert();
                }
              });
          }
        }
      }
    },
    destoryVideo (name) {
      if (name === 'SpaceChoice') {
        this.$VideoPlayer.onPause();
      }
    }
  },
  watch: {
    $route (to, from) {
      const { name: toName } = to;
      const { name: fromName } = from;
      this.controlShowFloating(toName);
      this.destoryVideo(toName);
      if (fromName === 'SpaceChoice' && toName === 'PlayerWS' && this.$store.state.division !== 'Building') {
        this.$VideoPlayer.startLoad(-1);
      }

      if (this.$store.getters['coupon/getSelectCupon'] && this.$store.getters['coupon/getCuponInfo']) {
        const { couponType } = this.$store.getters['coupon/getCuponInfo'];
        if (couponType === 'All') {
          if (fromName === 'SpaceInsert' && toName !== 'SpaceInsertStore' && toName !== 'SpaceInsertCar') {
            this.$store.commit('coupon/setSelectCupon', false);
            this.$store.commit('coupon/setCuponInfo', null);
          }
        } else if (couponType === 'Store') {
          if (fromName === 'SpaceInsertStore' && toName !== 'SpaceInsertStoreColor') {
            this.$store.commit('coupon/setSelectCupon', false);
            this.$store.commit('coupon/setCuponInfo', null);
          }
        } else if (couponType === 'Car') {
          if (fromName === 'SpaceInsertCar' && toName !== 'SpaceInsertCarPurpose') {
            this.$store.commit('coupon/setSelectCupon', false);
            this.$store.commit('coupon/setCuponInfo', null);
          }
        }
      }
    }
  },
  destroyed () {
    this.$VideoPlayer.removeVideoEvent('play', this.videoPlay);
    this.$VideoPlayer.removeVideoEvent('pause', this.videoPause);
    this.isSpaceConnection();
  }
});
</script>
<style>
#raincanvas {
  position: fixed;
}

#raincanvas.dim {
  z-index: 0 !important;
}

#raincanvas.back {
  z-index: -1 !important;
}

body {
  width: 100%;
  height: 100%;
  background: #151515;
  overscroll-behavior-y: contain;
  -webkit-touch-callout: none;
  /* overflow: auto; */
}

body.white {
  background: var(--whitefff);
  position: fixed;
  /* position: sticky; */
}
</style>
<style scoped>
.video_group {
  overflow: hidden;
  height: 0;
}
</style>
<style scoped>
#layout4 {
  height: 100%;
}
</style>
