import { render, staticRenderFns } from "./NoticeModalComponent.vue?vue&type=template&id=4f5948f6&scoped=true"
import script from "./NoticeModalComponent.vue?vue&type=script&lang=js"
export * from "./NoticeModalComponent.vue?vue&type=script&lang=js"
import style0 from "./NoticeModalComponent.vue?vue&type=style&index=0&id=4f5948f6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f5948f6",
  null
  
)

export default component.exports